*{
	padding: 0;
	margin: 0;
    box-sizing: border-box;
  
}
body{
	background: rgba(215, 211, 211, 0.3);
	font-family: 'Poppins', sans-serif;
}
.conte{

	margin: 0 ;
	display: grid;
	grid-gap: 0px;
	grid-template-columns: repeat(3, 1fr);

	grid-template-rows: repeat28, auto3;
	grid-template-areas: 
            "header     header      header      header"
            "slider     slider      slider      slider"
            "cards      cards       cards       cards"
            "slider2    slider2     slider2     slider2"
            "graficas   graficas    graficas    graficas"
            "objetivo   objetivo    objetivo    objetivo"
            "sponsor    sponsor     sponsor     sponsor"
            "footer     footer      footer      footer";
}
/*---------------------------------------------*/
/*          menuPrincipal(header)              */
/*---------------------------------------------*/
.conte .cabeceraPrincipal{
	grid-area: header;
	background-color: #EEF0F4;
	display: flex;
	justify-content: space-between
}
.conte .cabeceraPrincipal .logotipo{
	padding: 10px 40px;
}

.conte .cabeceraPrincipal .menuPrincipal1{
	/*display: flex;*/
	align-items: center;
	padding-top: 5%;
}
.menuPrincipal1 a{
	text-decoration: none;
	padding: 0px 20px;
	transition-duration: 0.4s;
	color: #000;	
	border-radius: 10px;
  font-size: 17px;
  font-weight: 300;
  border-radius: 20px;
}
.menuPrincipal1 a:hover{
	background-color: #538b9f;
	color: #fff;
}

.conte .cabeceraPrincipal .menuPrincipal2{
	display: flex;
	align-items: center;
}
.menuPrincipal2 a{
	text-decoration: none;
	padding: 0 10px;
	color: black;
  
}

/*---------------------------------------------*/
/*             Slider principal                */
/*---------------------------------------------*/
.conte .sliderPrincipal{
	grid-area: slider;
	background-color: #fff;
}
.conte .sliderPrincipal img{
	width: 100%;
	height: auto;
}

.sliderPrincipal{
    position: relative;
    display: inline-block;
    text-align: center;
}
.textoGrande{
    position: absolute;
    top: 28%;
    left: 10px;
    color: #fff;
    font-size: 80px;
    text-shadow: 1px 1px 3px  black, 0 0 25px black;
    margin-left: 40px;
    text-align: left;  
    font-weight: 500;
}
.textoPequeño{
    position: absolute;
    top: 65%;
    left: 10px;
    color: #ffffff;
    font-size: 23px; 
    margin-left: 40px;
    font-family: Arial;
    text-shadow: 1px 1px 3px  black, 0 0 25px black;
    text-align: left;
    font-weight: 200;
}
.botonesPortada{
    position: absolute;
    top: 550px;
    left: 10px;
    color: #fff;
    font-size: 25px;
    margin-left: 40px;
    display: flex;
	  width: 500px; 
}
.botonesPortada a{
  margin: 3px;
  text-decoration: none;
  color: #000;
}


/*---------------------------------------------*/
/*                 Cards fotos                 */
/*---------------------------------------------*/
.conte .cards{
	grid-area: cards;

	text-align: center;
	background-color: white;
	padding: 30px 0px 60px ;
  position: relative;

}
.team{
	color: #000;
	font-size: 33px;
	padding: 15px 0px;
	margin: 15px;
}
.cards-container{
    width: 100%;
    max-width: 1480px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: auto;
}
.cards-container .card{
    width: 330px;
    height: 585px;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    margin: 20px;
    transition: all 0.25s;
}
.cards-container .card:hover{
    transform: translateY(-15px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}
.cards-container .card img{
    width: 330px;
    height: 320px;
    object-fit: cover;
}
.cards-container .card h3{
    font-weight: 200;
    text-align: left;
    padding: 10px 15px;
}
.cards-container .card p{
    padding: 0 1rem;
    font-weight: 200;
    text-align: left;
    font-size: 14px; 
}

.cards-container .card a{
  background-color: #144759; 
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 18px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 9px;
}
.cards-container .card a:hover{
  background-color: #4986cb;
  color: white;
}


/*---------------------------------------------*/
/*              Slider(carusel)                */
/*---------------------------------------------*/
.conte .carrusel {
	grid-area: slider2;
}
.slide{
  position: relative;
}
.slide h3{
  text-align: center;
  top: -600px;
  left: 370px;
  position: absolute;
  font-size: 30px;
}
.slide h6{
  text-align: left;
  top: -430px;
  left: 20px;
  position: absolute;
  font-size: 30px;
  font-weight: 400;
}
.slide p{
  text-align: left;
  top: -270px;
  left: 20px;
  position: absolute;
  font-size: 20px;
  font-weight: 10;
}
.slide .sli{
  text-align: right;
  top: -430px;
  left: 760px;
  position: absolute;
}


/*---------------------------------------------*/
/*                   Graficas                  */
/*---------------------------------------------*/
.conte .graficas{
	grid-area: graficas;
	background-color: #ffffff;
  height: 100%;
  position: relative;
}
.conte .graficas .centroGrafica {
	text-align: center;
	font-size: 30px;
	margin: 40px;
	color: black;
  font-family: 'Poppins';
  font-weight: 500;
}
.conte label{
	margin: 0px 180px;
  
}
.conte select{
	margin: 0px -160px;
  
}
.graficas p{
	margin-bottom: -40px;
  font-weight: 900;
}
.conte .graficas .lastReportData .chart-container .lastReportData mt-5{
  width: 50%; /* Ancho del 50% del contenedor padre (objetivo) */
  font-weight: normal; /* Restablece el peso de la fuente */
}

.lastReportData{
  display: flex;
  align-content: center;
  height: 450px;
}
.chart-container{
  display: flex;
  align-content: center;
  height: 50%;
  width: 50%;
}
.creado-en {
  font-weight: bold;
  margin: 2px 45%; /* Espacio de 2px arriba y abajo */
  text-align: center;
  position: center;
  border-bottom: 2px solid black; /* Línea negra de 2px de grosor */
  max-width: 150px;
  flex-direction: column;
  display: flex;
}

/*---------------------------------------------*/
/*               Objetivo               */
/*---------------------------------------------*/
.conte .objetivo{
	grid-area: objetivo;
	background-color: #E2E6E7;
  height: 100%;
  position: relative;
}
.conte .objetivo .centroObjetivo{
	text-align: center;
	font-size: 35px;
	padding: 1em ;
	color: black;
  font-family: 'Poppins';
}
.conte .objetivo .ConceptoObjetivo {
    top: 200px;
    left: 710px;
    position: absolute;
    color: black;
    font-size: 22px;
    font-family: 'poppins';
    font-weight: 200;
}
.conte .objetivo {
  grid-area: objetivo;
  background-image: url("./images/fotoObjetivo.jpg"); /* Ruta de la imagen de fondo */
  background-position: 50px; /* Alinea la imagen de fondo a la izquierda */
  background-repeat: no-repeat; /* Evita la repetición de la imagen de fondo */
  background-size: 200px auto; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  height: 100%;
  position: relative;
}
.conte .objetivo .botonesObjetivo{
    top: 330px;
    left: 700px;
    position: absolute;
    display: flex;
  align-items: center;
  margin-left: 2px;
}
.botonesObjetivo a{
  margin: 20px;
  font-family: poppins(Thin);
  text-decoration: none;
  color: white;
}
.conte .objetivo .tabla{
  padding: 2em;
}
/* Estilos para la tabla */
.conte .objetivo .mi-tabla {
  width: 50%; /* Ancho del 50% del contenedor padre (objetivo) */
  margin: 0 auto; /* Centra la tabla horizontalmente */
  border-collapse: collapse; /* Fusiona los bordes de las celdas */
  font-weight: normal; /* Restablece el peso de la fuente */
}

/* Hacer el contenido de las celdas transparente */
.conte .objetivo .mi-tabla td {
  background-color: transparent; /* Fondo transparente */
  color: black; /* Texto negro */
  border: 1px solid black; /* Borde negro en todas las celdas */
  padding: 10px; /* Espaciado interior en las celdas */
  text-align: center; /* Centra el texto horizontalmente */
}

/* Estilos solo para la primera fila */
.conte .objetivo .mi-tabla tr:first-child td {
  background-color: rgba(128, 0, 128, 0.5); /* Fondo morado transparente */
  font-weight: bold; /* Texto en negrita */
}

/* Establecer el ancho de la segunda columna */
.conte .objetivo .mi-tabla td:nth-child(2) {
  width: 150px; /* Ancho específico para la segunda columna */
}

/* Estilos solo para la primera columna */
.conte .objetivo .mi-tabla td:first-child {
  background-color: rgba(128, 0, 128, 0.5); /* Fondo morado transparente */
  font-weight: bold; /* Texto en negrita */
}



/*---------------------------------------------*/
/*                  Sponsors                   */
/*---------------------------------------------*/
.conte .sponsor{
	grid-area: sponsor;
	background-color: #ffff;
	text-align: center;
	padding: 1em 0;

}
.conte .sponsor .centroSponsor {
  color: white;
  font-size: 25px;
  padding: 2em 3em 1em ;
  color: #144759;
}
.conte .imagenesSponsor img{
margin: 20px 80px;
padding: 0px 10px  50px 40px;

}

/*---------------------------------------------*/
/*           Pie Pagina (footer)               */
/*---------------------------------------------*/

.conte .piePagina{
  grid-area: footer;
  background-color: #fff;
}
.conte .piePagina img{
  width: 100%;
  height: 100%;
}
.piePagina{
    position: relative;
    display: inline-block;
    text-align: center;
}
.textoFooter1 {
  position: absolute;
  top: 45px;
  left: 125px;
  padding: 1em;
  text-align: left;     
}
.textoFooter1 h4{
color: white;
text-align: left;
font-size: 25px; 

}
.textoFooter1 p{
color: white;
text-align: left; 
font-size: 15px;
font-weight: 200;
}

.textoFooter2 {
    position: absolute;
    top: 45px;
    left: 690px;
    padding: 1em;
    text-align: left;     
}
.textoFooter2 h4{
  color: white;
  text-align: left;
  font-size: 25px; 

}
.textoFooter2 p{
  color: white;
  text-align: left; 
  font-size: 15px;
  margin-top: 0px;
  font-weight: 200;
  
}
.iconos{
    position: absolute;
    top: 60px;
    left: 1230px;
    padding: 0 25px;
    color: white;
}
.iconos a{
    font-size: 25px;
    padding: 0 10px;
    color: white;
}

.centroDerechos{
    position: absolute;
    top: 93%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'poppins';
    color: white;
    font-size: 0.1em;
}

/*---------------------------------------------*/
/*                   Botones                  */
/*---------------------------------------------*/

 /* BOTONES DEL MENU */
.botonMenu1 {
  background-color: #fff; 
  border: none;
  color: #000;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}
.botonMenu1:hover {
  background-color: #a9b7d3;
  color: #fff;
}

.botonMenu2 {
  background-color: #144759; 
  border: none;
  color: white;
  padding: 10px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}
.botonMenu2:hover {
  background-color: #2471A3;
  color: white;
}

/* BOTONES DE LA PORTADA*/

.botonPortada1 {
  background-color: #fff; 
  border: none;
  color: #000;
  padding: 15px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}
.botonPortada1:hover {
  background-color: #a9b7d3;
  color: #fff;
}

.botonPortada2 {
  background-color: #144759; 
  border: none;
  color: white;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}
.botonPortada2:hover {
  background-color: #4986cb;
  color: #fff;
}

/* BOTONES DE OBJETIVO*/
.botonObjetivo1 {
  background-color: #144759; 
  border: none;
  color: white;
  padding: 10px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}
.botonObjetivo1:hover {
  background-color: #4986cb;
  color: #fff;
}

.botonObjetivo2 {
  background-color: #144759;
  border: none;
  color: white;
  padding: 10px 23px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}
.botonObjetivo2:hover {
  background-color: #4986cb;
  color: #fff;
}
.botonObjetivo3 {
  background-color: #144759; 
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}
.botonObjetivo3:hover {
  background-color: #4986cb;
  color: #fff;
}

.respmenu input[type="checkbox"], .respmenu .fa-bars, .respmenu .fa-times {
  display:none;

}


/*---------------------------------------------*/
/*                   version movil                  */
/*---------------------------------------------*/

.solo-movil{
display: none;
}



@media (max-width: 739px){
.solo-movil{
display: block;
}	
.solo-pc{
display: none !important;
}

.textoGrande {
  top: 10%;
  font-size: 30px;
  line-height: 25px;
}

.textoPequeño {
  top: 40%;
  font-size: 15px;
  background: transparent;
  padding: 0.5%;
}

.conte .cards {
  grid-area: cards;
  text-align: center;
  background-color: white;
  position: relative;
  display: block;
  z-index: 99;
}

.conte .cards .cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px 5px;
}

.conte .graficas {
  grid-area: graficas;
  text-align: center;
  background-color: white;
  padding: 2px 0px 30px;
  position: relative;
  display: block;
}
.conte .graficas .chart-container{
  grid-area: graficas;
  text-align: center;
  background-color: white;
  padding: 2px 0px 30px;
  position: relative;
  display: block;
  width: auto !important;
}
.graficas .container {   
  height: auto;   
}
.cards .container {   
  height: auto;   
}

.d-none {
  display: block !important;
}


.conte .objetivo {
  grid-area: objetivo;
  background-color: #E2E6E7;
  height: 100%;
  position: relative;
}

.conte .objetivo .ConceptoObjetivo {
  top: 325px;
  left: 15px;
  position: relative;
  color: black;
  font-size: 21px;
  font-family: 'poppins';
  font-weight: 100;
}

.conte .objetivo {
  grid-area: objetivo;
  background-image: url("./images/fotoObjetivo.jpg"); /* Ruta de la imagen de fondo */
  background-position: 5px 10px; /* Alinea la imagen de fondo a la izquierda */
  background-repeat: no-repeat; /* Evita la repetición de la imagen de fondo */
  background-size: 100px auto; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  height: 100%;
  position: relative;
}
.conte .objetivo .botonesObjetivo {
  top: 825px;
  left: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.botonesObjetivo a {
  margin: 0px;
  font-family: poppins(Thin);
  text-decoration: none;
  color: white;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 17.25rem !important;
  left: 12%;
  padding-top: 0.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  display: block;

}
.carousel-caption p{
    margin-bottom: 40px;

}
.d-block {
  display: block!important;
  height: 800px;
}

.conte .piePagina {
  background-color: #09717c !important;
  width: 375px !important;
  display: block;
  max-width: 100%;
  position: relative;
  background: #09717c !important;
}
.conte .piePagina img {
  width: 100%;
  height: 576px;
  background: #09717c;
}
.textoFooter1 {
  position: absolute;
  top: 50px;
  left: 70px;
  padding: 1em;
  text-align: left;
  width: 82%;
}


.textoFooter2 {
  position: absolute;
  top: 38%;
  left: 70px;
  padding: 1em;
  text-align: left;
  width: 80%;
  display: block;
  /* float: unset; */
}

.iconos {
  position: absolute;
  top: 400px;
  left: 195px;
  padding: 0 25px;
  color: white;
}

.centroDerechos {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'poppins';
  color: white;
  font-size: 0.1em;
}

.conte .graficas {   
    height: center;    
}

.lastReportData {    
    display: initial !important;    
}

.lastReportData div {
    height: center;
}


.conte .imagenesSponsor img {
    margin: 20px 60px !important;
    
}

/* menu movil*/

.respmenu a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 20px;
  border-bottom: 2px solid #456789;
  max-width: 200px;
  background: #234567;
  font-variant: small-caps;
  text-shadow: 1px 1px black;
}

.respmenu input[type="checkbox"], .respmenu .fa-bars, .respmenu .fa-times {
  display:block;
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  right: -220px;
  top: 0;
  width: 35px;
  height: 35px;
}

.respmenu .fa-bars, .respmenu .fa-times {
font-size: 48px;
pointer-events: none;
}

.respmenu input[type="checkbox"] {
opacity: 0;
}

.respmenu {
  color: #000;
  position: relative;
  background: #123456;
  min-height: 30px;
  margin-left: -65%;
  top: 15%;
}

.respmenu nav {
display: none;
}

.respmenu input:checked ~ nav {
display: block;
}

.respmenu input:checked ~ .fa-bars {
display: none;
}

.respmenu input:not(:checked) ~ .fa-times {
display: none;
}

.conte .cabeceraPrincipal .logotipo {
  padding: 10px 0px;
}

nav.menuPrincipal1 {
  z-index: 999;
  position: absolute;
  background: #234567;
}

.menuPrincipal2 a {
  text-decoration: none;
  padding: 0 5px;
  color: black;
}
.botonMenu1 {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 12px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}

.botonMenu2 {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 18px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
}

.conte label {
  margin: 10px 150px;
}

.botonesPortada {    
  top: 192px;   
width: 330px;
}
.botonPortada1 { 
padding: 9px 9px; 
font-size: 9px; 
}

.botonPortada2 { 
padding: 9px 9px; 
font-size: 9px;  
}

.conte .sliderPrincipal img {   
  height: 240px;
}

}
@media (min-width: 740px) and (max-width: 1185px){
	.conte .objetivo .botonesObjetivo {  
    left: 60px;   
}

.iconos {   
    left: 40%;    
}	

.conte .objetivo .ConceptoObjetivo {    
    left: 550px;    
}

.textoFooter2 {    
    left: 550px;    
}
}

@media (min-width: 850px) and (max-width: 1185px){
.textoGrande {   
    top: 0%;    
    line-height: 70px;
}
.textoPequeño {    
    top: 35%;   
}
	
	.botonesPortada {   
    top: 130px;   
}

.container .card {
    width: 300px;   
}
}


th, td{
    color: #b0b0b0;
}

